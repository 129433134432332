
<template>
    <div class='system_config'>
        <!-- 标题 -->
        <div class="vue_title">系统配置</div>
        <div class="tabs">
            <div class="tabs-item" :class="{active:item.name==activeName}" v-for="item in tabList" :key="item.name" @click="changeTabs(item.name)">{{ item.desc }}</div>
        </div>
        <div class="height">
            <ul>
                <li>
                    <div class="lable">配置项</div>
                    <div class="cs">参数</div>
                </li>
                <li v-for="(item,index) in list[activeName]" :key="index">
                    <div class="lable">{{item.desc}}</div>
                    <div class="inp" v-if="item.type=='input'">
                        <el-input v-model="item.value" size="medium" :placeholder="'请输入'+item.desc"></el-input>
                        <div class="box" v-if="item.category=='subscribe'">%</div>
                        <div class="box" v-if="item.category=='invite_code'">次</div>
                    </div>
                    <div class="text" v-if="item.type=='textarea'">
                        <el-input type="textarea" :rows="6" v-model="item.value" size="medium" :placeholder="'请输入'+item.desc"></el-input>
                    </div>
                </li>
            </ul>
        </div>
        <div class="bottom-btn">
            <el-button size="small" class="btnBgColor_blue btn" :loading="btnLoading" @click="updata">保存</el-button>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            tabList: [],
            activeName: 'wx_conf',
            list: {},
            loading: false,
            btnLoading: false,
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        changeTabs(val) {
            this.activeName = val
        },
        // 更新
        updata() {
            let arr = {};
            // console.log(this.list)
            // console.log(this.activeName)
            // console.log(Object.values(this.list[this.activeName]))

            // return
            let newList = Object.values(this.list[this.activeName])
            newList.forEach(ele => {
                arr[ele.name] = ele.value
            })
            let obj = {
                ...arr
            }
            this.btnLoading = true;
            this.$setupApi.postConfigs(obj).then(res => {
                if (res.code == 1000) {
                    this.btnLoading = false;
                    this.$succMsg(res.message)
                } else {
                    this.btnLoading = false;
                    this.$errMsg(res.message)
                }
            })
        },
        getInfo() {
            this.loading = true;
            this.$setupApi.getConfigs().then(res => {
                if (res.code == 1000) {
                    console.log(res)
                    this.loading = false;
                    this.tabList = res.result.category;
                    this.activeName = res.result.category[0].name;
                    this.list = res.result.list
                } else {
                    this.loading = false;
                    this.$errMsg(res.message)
                }
            })
        }
    },
}
</script>
<style lang='scss' scoped>
.system_config {
    .header {
        width: 100%;
        height: 60px;
        border-bottom: 2px solid #e7e7e7;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 60px;
        padding-left: 20px;
    }
    .tabs {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        background: #f7f7f7;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        display: flex;
        .tabs-item {
            width: 121px;
            text-align: center;
            line-height: 40px;
            height: 40px;
            font-size: 14px;
            color: #666666;
            cursor: pointer;
        }
        .tabs-item.active {
            color: #2e4bf2;
            background: #ffffff;
            border-bottom: 1px solid #ffffff;
        }
    }
}

.height {
    height: calc(100vh - 250px);
    overflow-y: auto;
}
.bottom-btn {
    border-top: 1px solid #e7e7e7;
    padding-top: 15px;
    padding-left: 20px;
    .btn {
        width: 86px;
    }
}
ul {
    padding: 20px;
}
li {
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    .cs {
        padding-top: 6px;
        color: #333333;
        font-size: 14px;
    }
    .lable {
        width: 130px;
        // text-align: right;
        margin-right: 12px;
        padding-top: 6px;
        color: #666666;
        font-size: 14px;
    }
    .inp {
        display: flex;
        width: 400px;
        .box {
            width: 60px;
            height: 36px;
            background: #2e4bf2;
            border-radius: 0px 4px 4px 0px;
            margin-left: -2px;
            z-index: 100;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 36px;
        }
    }
    .text {
        width: 400px;
    }
}
</style>